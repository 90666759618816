import Container from './Container';
import backgroundImg from '../images/aboutBackgroundImg.svg'
import aboutUserImg from '../images/aboutUserImg.svg'

import lowerImg from '../images/Group 16.svg'
const About = () => {
    return (
        <div className="-mt-1">
       
        <div  id="about" className="  bg-[#3C4E8C] py-3">
         <Container>
            {/* main div */}
           <div className="flex flex-col lg:flex-row justify-between items-center">
                {/* user div */}
                <div className="">
                    <img src={aboutUserImg} className=' rounded-xl sm:max-w-screen-[400px] lg:max-h-[516px] lg:max-w-[527px]' alt="" />
                </div>
                {/* text div */}
                <div className=" xs:px-1  xs:text-center md:text-left xl:ms-28">
                    <h1 className='font-bold text-[#F8F9FC] text-5xl '>
                        About Me
                    </h1>
                    <h1 className='font-semibold my-3 text-[#F8F9FC] text-3xl 2xl:text-4xl'>
                        Here are some words about me 
                    </h1>

                    <h2 className='my-2 py-1 text-[#F8F9FC] text-lg sm:text-center lg:text-left xl:text-2xl 2xl:text-3xl'>
                    At Xeurix, our team's commitment to excellence in web application development is unwavering. With a strong foundation in Computer Software Engineering from the University of Sargodha, our focus is on creating high-impact React.js and Node.js solutions. We prioritize seamless collaboration with cross-functional teams to meet project requirements and deliver on time.
                    </h2>

                    <h2 className='my-2 text-[#F8F9FC] text-lg  sm:text-center lg:text-left xl:text-2xl  2xl:text-3xl '>
                    As a mentor and visiting lecturer at Beaconhouse International College, I blend academic perspectives with real-world development practices, fostering a robust learning environment. This dual role enhances my ability to provide constructive feedback, resolve complex technical challenges, and ensure our web solutions set industry standards for innovation and user experience.
                    </h2>
                </div>
           </div>
         </Container>   
        </div>
         <img src={lowerImg} className='-mt-1'/>
         </div>
    );
};

export default About;

{/* <div>
<h6 className='md:text-base text-xl text-[#009bdf] font-semibold py-4 pt-12'>About me</h6>
<p className='md:text-4xl text-xl font-bold'>

    I am a Experienced Full Stack Developer proficient in JavaScript, React, and Node.js. Excels in collaboration, troubleshooting, and mentoring. Committed to innovation and surpassing development targets.
</p>
</div>
<div className=' md:flex py-20 '>
<div className='md:w-[50%] w-full'>
    <p className='text-xl md:text-4xl  text-[#009bdf] w-[80%] font-bold'>i design professional and beautiful websites</p>
</div>
<div className='md:w-[50%] w-full pt-1 pb-6'>
    <p className='pb-4 md:text-sm text-base leading-6'>
        With years of experience in graphic and web design I have mastered the skills of
        understanding client requirements according to the latest trends.
        I have worked with businesses from different niches so you can rely on me for yours.
    </p>
    <p className='text-sm text-[#758398] leading-7'>
        I’ve spent most of these years working across different areas of design like front-end development,
        landing pages, email design, app UI/UX, to my current role designing products for mobile platforms.
        Having worked on various projects that are already live, I can help you with the best possible suggestions and ideas that we can proceed with. With me, you aren’t forced to accept anything. I give you a variety of options we can work on together.
    </p>
    <button className='py-4 px-2 text-[#009bdf]'>Read More</button>
</div>
</div>









*/}