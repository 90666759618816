import Container from './Container';
import mobileAppIcon from '../images/Mobileapp.svg';
import websiteIcon from '../images/Website.svg';
import designingIcon from '../images/Designing.svg';
import aiIcon from '../images/Ai development.svg';

import cloudIcon from '../images/cloud.svg';
import machineIcon from '../images/machineIcon.svg';
import { ReactComponent as AiIcons } from '../images/Ai development.svg';
const services = [
  { 
    title: "Mobile App Development",
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: mobileAppIcon 
  },
  { 
    title: "Websites Development", 
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: websiteIcon
     },
  { 
    title: "Website Designing",
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
      icon: designingIcon 
    },
  {
     title: "AI Development", 
     additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: aiIcon
     },
  {
     title: "Machine Learning",
     additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
       icon: machineIcon 
      },
  { 
    title: "Cloud Services", 
    additionalContent: "What is a basic definition of text? Text is the exact, original words written by an author. Text is also a specific work as written by the original author. Text is also commonly used to refer to a text message or to send a text message.", 
     icon: cloudIcon
     }
];

const WhatIDo = () => {
  return (
    <div id="WhatIDo" className="bg-[#F8F9FC] pb-7">
      <Container>
      <div className="">
        <h1 className="text-center text-5xl font-bold text-[#3C4E8C] mb-8">WHAT I DO</h1>
        <div className="grid grid-cols-1 gap-x-[1px] gap-y-[1px] sm:grid-cols-2 lg:grid-cols-3 mx-auto">
          {services.map((service, index) => (
            <div key={index} className="relative w-full sm:h-[360px] md:h-[320px]  xl:h-[320px] 2xl:h-[400px] group">
              {/* Card */}
              <div className="relative  p-6 w-full h-full shadow-lg rounded-lg transition-transform duration-300 ease-in-out group-hover:shadow-lg overflow-hidden">
                {/* Icon + Title positioned at the bottom initially */}
                <div
                 className="absolute w-4/5  bottom-20 left-1/2 transform -translate-x-1/2 flex flex-col
                 items-center transition-transform duration-300 ease-in-out xs:group-hover:-translate-y-[90px] 
                 sm:group-hover:-translate-y-[130px] md:group-hover:-translate-y-[120px] lg:group-hover:-translate-y-[130px]
                  xl:group-hover:-translate-y-[100px] 2xl:group-hover:-translate-y-[200px] 3xl:group-hover:-translate-y-[160px] ">
                  {service.icon && (
                    <div className="xs:w-20 xs:h-20 lg:w-70 lg:h-70 mb-2 "  >
                    {service.icon && (
                        <img src={service.icon} className="w-full h-full object-contain" alt={`${service.title} Icon`} />
                      )}
                      </div>
                  )}
                  <h2 className="text-xl text-center xl:text-2xl font-bold text-blue-800">{service.title}</h2>
                </div>
                {/* Additional Content */}
                <div className="absolute text-center inset-x-0 mx-auto w-11/12 sm:w-full md:w-11/12  bottom-2 transform translate-y-full group-hover:translate-y-0 transition-transform duration-300 ease-in-out  p-4 text-gray-600 opacity-0 group-hover:opacity-100">
                  <p className='2xl:text-2xl'>{service.additionalContent}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </Container>
    </div>
  );
};

export default WhatIDo;
