import Container from './Container';

const Exprience = () => {
    return (
        <div id='experience' className='bg-[#F8F9FC] w-full flex items-center'>
            <Container>
                <div>
                    <h1 className=' text-[#3C4E8C] text-2xl mt-12 font-bold font-poppins pb-16 md:text-5xl '>
                        My Experience
                    </h1>
                </div>

                <div className='flex'>
                    <div className='flex flex-col pr-8 w-[30%] md:w-[15%]'>
                        <p className='w-full pb-2 border-b  md:w-[120px] xl:text-xl 3xl:text-2xl  '>2021-Present</p>
                        <p className='text-[#758398] pt-2 font-normal xl:text-xl 3xl:text-2xl '> Xeurix, Lahore</p>
                    </div>
                    <div className='w-full'>
                        <h1 className='pb-1 font-semibold text-[#009bdf] text-xl xl:text-3xl 3xl:text-4xl'>React.js and Node.js Developer
                        </h1>
                        <ul>
                            <li className='text-xl xl:text-2xl 3xl:text-3xl'> Contributed to web development projects, collaborating with a team to deliver
                                high-quality solutions within tight deadlines.Gained valuable experience in front-end and back-end technologies, further
                                honing problem-solving skills.
                            </li>
                           
                        </ul>
                    </div>

                </div>

                <div className='flex pt-12'>
                    <div className='flex flex-col w-[30%] md:w-[15%] pr-8'>
                        <p className='w-full md:w-[120px] pb-2 border-b xl:text-xl 3xl:text-2xl  '>2020-2021</p>
                        <p className='text-[#758398] pt-2 font-normal xl:text-xl 3xl:text-2xl '>CSYSOL, Faisalabad</p>
                    </div>
                    <div className='w-full '>
                        <h1 className='pb-1 font-semibold text-[#009bdf] text-xl xl:text-3xl 3xl:text-4xl'>MERN Stack Developer</h1>
                        <ul>
                            <li  className='text-xl xl:text-2xl 3xl:text-3xl'>
                                Played a key role in project planning and delivery, ensuring seamless communication between team members. Implemented clean, efficient, and maintainable code, resulting in enhanced
                                application performance and user experience.
                            </li>
                            
                        </ul>
                    </div>
                </div>
                {/* 2nd  */}

                <div className='flex pt-12'>
                    <div className='flex flex-col pr-8 w-[30%] md:w-[15%] '>
                        <p className='w-full md:w-[120px] pb-2 border-b xl:text-xl 3xl:text-2xl '>2019-2020</p>
                        <p className='text-[#758398] pt-2 font-normal xl:text-xl 3xl:text-2xl '>Dzine Media</p>
                    </div>
                    <div className='w-full'>
                        <h1 className='pb-1 font-semibold text-[#009bdf] text-xl  xl:text-3xl 3xl:text-4xl'>Web Developer
                        </h1>
                        <ul>
                            <li  className='text-xl xl:text-2xl 3xl:text-3xl'> Contributed to web development projects, collaborating with a team to deliver
                                high-quality solutions within tight deadlines.Gained valuable experience in front-end and back-end technologies, further
                                honing problem-solving skills.
                            </li>
                            
                        </ul>
                    </div>

                </div>
                {/* 3RD */}

                <div className='flex pt-12'>
                    <div className='flex flex-col w-[30%] md:w-[15%] pr-8 '>
                        <p className='w-full md:w-[120px] pb-2 pl-0 border-b xl:text-xl 3xl:text-2xl '>2018-2019</p>
                        <p className='text-[#758398] pt-2 font-normal xl:text-xl 3xl:text-2xl '>Xacrosoft , Remote</p>
                    </div>
                    <div className='w-full '>
                        <h1 className='pb-1 font-semibold text-[#009bdf] text-xl xl:text-3xl 3xl:text-4xl'>Junior Web Developer</h1>
                        <ul>
                            <li  className='text-xl xl:text-2xl 3xl:text-3xl'>
                                Assisted in the development and maintenance of web applications, gaining
                                exposure to various stages of the development lifecycle.Acquired proficiency in JavaScript, HTML, CSS, and basic web development
                                concepts.
                            </li>
                        </ul>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Exprience;