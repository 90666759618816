import Container from "./Container";
import headerimg from "../images/userpic.svg";
import upperWaveImg from '../images/upperwaves.svg'
const Home = () => {
  return (
    <div className=" bg-pink-500  ">
     <section id="home" className=" bg-[#F8F9FC]   ">
      <Container className="">
    {/* content div  */}
      <div className="flex flex-col md:flex-row  justify-between items-center gap-2">
        {/* text-div */}
          <div className="md:w-[45%] flex flex-col w-full xs:text-center md:text-left">
              <h5 className="flex font-semibold md:mt-6 py-4 text-2xl xs:justify-center md:justify-start md:text-3xl 2xl:text-4xl ">
                Hi , I'm
              </h5>
              <h1 className="text-2xl  font-poppins md:text-4xl lg:text-6xl  md:font-bold lg:font-extrabold 2xl:text-7xl text-primary">
                Hamza Manzoor
              </h1>
             
              <h3 className=" mt-4 font-poppins  md:font-semibold text-2xl md:text-2xl lg:text-4xl lg:font-bold 2xl:text-6xl text-[#7081BB]">
                Full Stack Developer
              </h3>
            
             <div className="py-4">
             <button className=" ">
                <a
                  href="resume.pdf"
                  className=" py-2  px-4 text-xl text-white bg-primary rounded"
                  download="resume.pdf"
                >
                  Download CV
                </a>
              </button> 
             </div>
            </div>
         {/* image div */}
          <div className="md:w-3/5  w-full max-w-[650px]  flex justify-center items-center order-first md:order-none ">
            <div className="max-w-[450px  w-full p-[50%]  relative rounded-xl ">
              <img  src={headerimg} className="w-full h-full absolute z-10 top-0 left-0 "/>
              
            </div>
          </div>
</div>
      </Container>
      <img src={upperWaveImg} className="mt-9"/>
    </section>
    
    </div>
   
  );
};

export default Home;
{/* */}